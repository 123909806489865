import React from 'react'
import { Link } from 'gatsby'
import {
  Navbar,
  Container,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'

const Footer = ({ siteTitle }) => (
  <footer>
    <Container className="footer px-0">
      <Navbar
        dark
        className="d-flex flex-column flex-md-row align-items-center align-items-md-start"
      >
        <NavbarBrand tag={Link} to="/">
          {siteTitle} <small className="text-muted">berkeley, ca</small>
        </NavbarBrand>
        <Nav className="mr-4 d-flex flex-row mr-md-0">
          <NavItem>
            <NavLink tag={Link} to="/about/">
              about
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to="/privacy/">
              privacy
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to="/faq/">
              faq
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink tag={Link} to="/contact/">
              contact
            </NavLink>
          </NavItem>
        </Nav>
      </Navbar>
    </Container>
  </footer>
)

export default Footer
