import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { Container, Row } from 'reactstrap'
import { StaticQuery, graphql } from 'gatsby'
import Header from '../components/header'
import Footer from '../components/footer'
import '../layouts/index.scss'
import favicon from '../images/favicon.ico'
import appleTouchIcon from '../images/apple-touch-icon.png'
import favicon32x32 from '../images/favicon-32x32.png'
import favicon16x16 from '../images/favicon-16x16.png'
import safariPinnedTab from '../images/safari-pinned-tab.svg'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <div className="bg-gradient-dark h-100">
        <Helmet titleTemplate="%s | modosc designs">
          <meta charSet="utf-8" />
          <meta name="description" content="modosc designs" />
          <meta
            name="keywords"
            content="synthesizers buchla 700 bongo digital waveshaping"
          />
          <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
          <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
          <link rel="icon" type="image/png" sizes="32x32" href={favicon32x32} />
          <link rel="icon" type="image/png" sizes="16x16" href={favicon16x16} />
          <link rel="mask-icon" href={safariPinnedTab} color="#5bbad5" />
        </Helmet>
        <main>
          <Header siteTitle={data.site.siteMetadata.title} />
          <Container className="content-body">
            <Row>{children}</Row>
          </Container>
        </main>
        <Footer siteTitle={data.site.siteMetadata.title} />
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node,
}

export default Layout
