import React from 'react'
import { Link } from 'gatsby'
import {
  Navbar,
  Container,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap'

const Header = ({ siteTitle }) => (
  <Container>
    <Navbar
      dark
      fixed="top"
      className="container text-center bg-dark d-flex justify-content-around justify-content-md-between"
    >
      <NavbarBrand tag={Link} to="/" className="mr-0">
        {siteTitle}
      </NavbarBrand>
      <Nav className="d-none d-sm-flex" vertical={false}>
        <NavItem>
          <NavLink tag={Link} to="/id700/">
            ID700
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to="/about/">
            about
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to="/faq/">
            faq
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink tag={Link} to="/contact/">
            contact
          </NavLink>
        </NavItem>
      </Nav>
    </Navbar>
  </Container>
)

export default Header
